"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.expectError = exports.expectRefusal = exports.expectOk = exports.isResultLike = exports.isError = exports.err = exports.isOk = exports.ok = exports.isRefusal = exports.refuse = void 0;
function refuse(reason) {
    return { type: 'refusal', reason };
}
exports.refuse = refuse;
function isRefusal(result) {
    return result.type === 'refusal';
}
exports.isRefusal = isRefusal;
function ok(data) {
    return { type: 'success', data };
}
exports.ok = ok;
function isOk(result) {
    return result.type === 'success';
}
exports.isOk = isOk;
function err(error) {
    return { type: 'error', error };
}
exports.err = err;
function isError(result) {
    return result.type === 'error';
}
exports.isError = isError;
function isResultLike(result) {
    return (result &&
        typeof result === 'object' &&
        'type' in result &&
        ((result.type === 'success' && 'data' in result) || (result.type === 'refusal' && 'reason' in result)));
}
exports.isResultLike = isResultLike;
function expectOk(result) {
    expect(result.type).toBe('success');
}
exports.expectOk = expectOk;
function expectRefusal(result) {
    expect(result.type).toBe('refusal');
}
exports.expectRefusal = expectRefusal;
function expectError(result) {
    expect(result.type).toBe('error');
}
exports.expectError = expectError;
